import {
  type MakeValidatorFn,
} from '@/controllers/forms/forms.typedefs';

export const makeValidator: MakeValidatorFn = (
  validate,
  message,
) => (value) => {
  if (validate(value)) {
    return null;
  }

  return message;
};
