import {
  type FC,
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { ColorTheme, ColorThemePreference } from '@/components/common/ThemeContext/typedefs';
import { MEDIA_PREFERS_DARK_SCHEME, THEME_ATTRIBUTE } from '@/components/common/ThemeContext/constants';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorage';
import { emptyFunction } from '@/lib/helpers/functional';
import { analyticsSDK } from '@/controllers/analytics';
import { errorHandler } from '@/core/ErrorHandler';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { useTrackSystemColorTheme } from '@/components/common/ThemeContext/useTrackSystemColorTheme';
import { getColorTheme } from '@/components/common/ThemeContext/helpers';
import {
  AppMetaThemeColor,
} from '@/components/common/MetaThemeColor/AppMetaThemeColor/AppMetaThemeColor';

interface ContextProps {
  colorTheme: ColorTheme;
  setColorThemePreference: (
    colorThemePreference: ColorThemePreference
  ) => void;
  colorThemePreference: ColorThemePreference;
  isDarkMode: boolean;
}

export const PlatformThemeContext = createContext<ContextProps>({
  colorTheme: ColorTheme.Light,
  setColorThemePreference: emptyFunction,
  colorThemePreference: ColorThemePreference.System,
  isDarkMode: false,
});

interface Props {
  children?: ReactNode;
}

export const setThemeAttribute = (colorTheme: ColorTheme) => {
  document.documentElement.setAttribute(THEME_ATTRIBUTE, colorTheme);
};

export const PlatformThemeProvider: FC<Props> = ({ children }) => {
  const [
    colorThemePreference,
    setColorThemePreference,
  ] = useLocalStorage(
    LOCAL_STORAGE_KEYS.colorThemePreference,
    ColorThemePreference.System,
  );

  const { productName } = useSubDomainContext();

  const [
    colorTheme,
    setColorTheme,
  ] = useState(getColorTheme(productName, ColorTheme.Light));

  const setNewColorTheme = useCallback(
    (newColorTheme: ColorTheme) => {
      setColorTheme(newColorTheme);
      setThemeAttribute(newColorTheme);

      try {
        window.localStorage
          .setItem(
            LOCAL_STORAGE_KEYS.lastUsedColorTheme,
            newColorTheme,
          );
      } catch (error) {
        errorHandler.captureException(error, {
          logMessage: `[Theme context]: cant write lastUsedColorTheme to storage`,
          fields: {
            newColorTheme,
          },
        });
      }
    },
    [],
  );

  const getColorThemeByPreference = useCallback(
    (currentColorThemePreference: ColorThemePreference) => {
      switch (currentColorThemePreference) {
        case ColorThemePreference.Light:
          return getColorTheme(productName, ColorTheme.Light);

        case ColorThemePreference.Dark:
          return getColorTheme(productName, ColorTheme.Dark);

        case ColorThemePreference.System: {
          if (typeof window !== 'undefined' && window?.matchMedia) {
            const {
              matches: prefersDarkTheme,
            } = window.matchMedia(MEDIA_PREFERS_DARK_SCHEME);

            const chosenTheme = prefersDarkTheme
              ? ColorTheme.Dark
              : ColorTheme.Light;

            return getColorTheme(productName, chosenTheme);
          }

          return getColorTheme(productName, ColorTheme.Light);
        }

        default:
          return getColorTheme(productName, ColorTheme.Light);
      }
    },
    [productName],
  );

  const changeThemePreference = useCallback(
    (newColorThemePreference: ColorThemePreference) => {
      setColorThemePreference(newColorThemePreference);

      const newColorTheme = getColorThemeByPreference(newColorThemePreference);

      setNewColorTheme(newColorTheme);

      analyticsSDK.platformThemePreference
        .sendChangedEvent({ chosenTheme: newColorThemePreference });
    },
    [getColorThemeByPreference, setColorThemePreference, setNewColorTheme],
  );

  useTrackSystemColorTheme({
    condition: colorThemePreference === ColorThemePreference.System,
    setNewColorTheme,
  });

  const isDarkMode = useMemo(() => {
    const currentColorTheme = getColorThemeByPreference(colorThemePreference);

    return currentColorTheme === ColorTheme.Dark
      || currentColorTheme === ColorTheme.KnowelyDark;
  }, [colorThemePreference, getColorThemeByPreference]);

  useEffect(() => {
    const currentColorTheme = getColorThemeByPreference(colorThemePreference);

    setNewColorTheme(currentColorTheme);
  }, [getColorThemeByPreference, colorThemePreference, setNewColorTheme]);

  const value = useMemo(() => ({
    colorTheme,
    setColorThemePreference: changeThemePreference,
    colorThemePreference,
    isDarkMode,
  }), [
    colorTheme,
    changeThemePreference,
    colorThemePreference,
    isDarkMode,
  ]);

  return (
    <PlatformThemeContext.Provider
      value={value}
    >
      <AppMetaThemeColor />

      {children}
    </PlatformThemeContext.Provider>
  );
};

export const usePlatformTheme = () => (
  useContext(PlatformThemeContext)
);
