import React, {
  type FC,
  memo,
} from 'react';
import { TooltipWithPortal } from '@/components/ui/ToolTip/TooltipWithPortal';
import { ClassicTooltip } from '@/components/ui/ToolTip/ClassicTooltip/ClassicTooltip';
import { type ToolTipProps } from './tooltip.typedefs';

export const ToolTip: FC<ToolTipProps> = memo((
  props,
) => {
  const {
    renderWithPortal = false,
  } = props;

  return renderWithPortal
    ? <TooltipWithPortal {...props} />
    : <ClassicTooltip {...props} />;
});
