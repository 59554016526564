import { useState, useEffect, type RefObject } from 'react';
import { PLATFORM_HEADER_HEIGHT } from '@/lib/constants/sizes';
import { HorizontalPositionMode, VerticalPositionMode } from '../tooltip.typedefs';

interface Params {
  tooltipRef: RefObject<HTMLDivElement>;
  horizontalPosition: HorizontalPositionMode;
  verticalPosition: VerticalPositionMode;
  topScreenOffset?: number;
}

export const useClassicTooltipPosition = (params: Params) => {
  const {
    tooltipRef,
    horizontalPosition,
    verticalPosition,
    topScreenOffset = PLATFORM_HEADER_HEIGHT,
  } = params;

  const [
    actualHorizontalPosition,
    setHorizontalPosition,
  ] = useState<HorizontalPositionMode>(horizontalPosition);
  const [
    actualVerticalPosition,
    setVerticalPosition,
  ] = useState<VerticalPositionMode>(verticalPosition);

  const tooltip = tooltipRef.current;

  useEffect(() => {
    if (!tooltip) {
      return;
    }

    const tooltipRect = tooltip.getBoundingClientRect();

    const {
      left, top, width, height,
    } = tooltipRect;

    const shouldSetBottomPosition = top < topScreenOffset && (
      actualVerticalPosition === VerticalPositionMode.Top
    );
    const shouldSetTopPosition = top + height > window.innerHeight && (
      actualVerticalPosition === VerticalPositionMode.Bottom
    );
    const shouldSetRightPosition = left < 0 && (
      actualHorizontalPosition !== HorizontalPositionMode.RightSide
    );
    const shouldSetLeftPosition = left + width > window.innerWidth && (
      actualHorizontalPosition !== HorizontalPositionMode.LeftSide
    );

    if (shouldSetBottomPosition) {
      setVerticalPosition(VerticalPositionMode.Bottom);
    }

    if (shouldSetTopPosition) {
      setVerticalPosition(VerticalPositionMode.Top);
    }

    if (shouldSetRightPosition) {
      setHorizontalPosition(HorizontalPositionMode.RightSide);
    }

    if (shouldSetLeftPosition) {
      setHorizontalPosition(HorizontalPositionMode.LeftSide);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltip]);

  return {
    dynamicHorizontalPosition: actualHorizontalPosition,
    dynamiclVerticalPosition: actualVerticalPosition,
  };
};
