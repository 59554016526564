import { type FC, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  portalNode?: HTMLElement;
}

export const Portal: FC<Props> = ({ portalNode, children }) => {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const containerNode = document.createElement('div');

    document.body.appendChild(containerNode);

    setContainer(containerNode);

    return () => {
      document.body.removeChild(containerNode);
    };
  }, []);

  if (portalNode) {
    return createPortal(children, portalNode);
  }

  if (container) {
    return createPortal(children, container);
  }

  return null;
};
